"use client";
import NextError from "next/error";
import { useEffect } from "react";
import ErrorMessage from "../components/errorMessage";
import { logger } from "../utils/logger";

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    logger.exception(error);
  }, [error]);

  return (
    <html>
      <body>
        <ErrorMessage message="An unknown error has occurred" code={500} details={error.message} />
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <NextError statusCode={undefined as any} />
      </body>
    </html>
  );
}
