import * as Sentry from "@sentry/nextjs";

/**
 * Provides general logging functionality.
 */
export const logger = {
  exception: (e: Error | Pick<Error, "message">): void => {
    console.error(e);

    // Capture the exception with Sentry
    if (e instanceof Error) {
      Sentry.captureException(e);
    } else {
      Sentry.captureException(new Error(e.message));
    }
  },
};
